import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import ContactsIcon from '@material-ui/icons/Contacts';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PaymentIcon from '@material-ui/icons/Payment';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import 'react-toastify/dist/ReactToastify.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 750,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    select: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    deleteContainer: {
        marginTop: 90,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '22%',
            marginLeft: 15
        }
    },
    clear: {
        fontSize: 24,
        color: '#9f0000',
        "&:hover": {
            cursor: "pointer"
        }
    },
    notFound: {
        fontSize: 24,
        color: '#f0ad4e',
        "&:hover": {
            cursor: "pointer"
        }
    },
}));

const EditBooking = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const history = useHistory();
    const params = useParams();
    const roleId = localStorage.getItem('roleId');
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [formSubmitted, setFormSubmitted] = useState(false);
    let [booking, setBooking] = useState(null);
    let [packageTypes, setPackageTypes] = useState(null);
    let [buildings, setBuildings] = useState(null);
    let [selectedBuilding, setSelectedBuilding] = useState(null);
    let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
    let [packages, setPackages] = useState(null)
    let [allPackages, setAllPackages] = useState(null)
    let [selectedPackage, setSelectedPackage] = useState(null)
    let [selectedPackageObject, setSelectedPackageObject] = useState(null)
    let [plateNumber, setPlateNumber] = useState('');
    let [flatNumber, setFlatNumber] = useState('');
    let [quantity, setQuantity] = useState('');
    let [carTypes, setCarTypes] = useState('');
    let [selectedCarType, setSelectedCarType] = useState('');
    let [selectedCarTypeObject, setSelectedCarTypeObject] = useState(null);
    let [bookingItem, setBookingItem] = useState(null)
    const [value, setValue] = React.useState(0);

    let [staffs, setStaffs] = useState([]);
    let [selectedStaff, setSelectedStaff] = useState(null);
    let [selectedStaffObject, setSelectedStaffObject] = useState(null);

    let [startingDate, setStartingDate] = useState(null);
    let [shifts, setShifts] = useState([]);
    let [allShifts, setAllShifts] = useState([]);
    let [selectedShift, setSelectedShift] = useState(null);
    let [selectedShiftObject, setSelectedShiftObject] = useState(null);
    let [days, setDays] = useState([]);
    let [selectedDay, setSelectedDay] = useState(null);
    let [selectedDayObject, setSelectedDayObject] = useState(null);
    let [packageName, setPackageName] = useState('');
    let [columns, setColumns] = useState([]);
    let [staffSchedules, setStaffSchedules] = useState([]);
    let [shiftsList, setShiftsList] = useState([]);
    let [tableSchedules, setTableSchedules] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let [paymentStatusValues, setPaymentStatusValues] = useState([])
    let [paymentStatus, setPaymentStatus] = useState({})
    let [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
    let [paidOptionValues, setPaidOptionValues] = useState([])
    let [paidOption, setPaidOption] = useState({})
    let [selectedPaidOption, setSelectedPaidOption] = useState('')
    let [list, setList] = useState([]);
    let [promoCode, setPromoCode] = useState('');
    let [amount, setAmount] = useState(null);
    let [promoCodeApplied, setPromoCodeApplied] = useState(false);
    let [processing, setProcessing] = useState(false);

    let [parkingLevels, setParkingLevels] = useState([]);
    let [selectedParkingLevel, setSelectedParkingLevel] = useState(null);
    let [selectedParkingLevelObject, setSelectedParkingLevelObject] = useState(null);
    let [customers, setCustomers] = useState('');
    let [allCustomers, setAllCustomers] = useState('');
    let [selectedCustomer, setSelectedCustomer] = useState(null);
    let [customerInfo, setCustomerInfo] =  useState('');
    let [selectedCustomerObject, setSelectedCustomerObject] = useState(null);

    let [paymentReceived, setPaymentReceived] = useState(false);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        let paymentStatusValues = [
            {
                'type_en': 'Not paid',
                'type_ar': 'غير مدفوع',
                'type_value': 'not_paid'
            },
            {
                'type_en': 'Others',
                'type_ar': 'مدفوع',
                'type_value': 'others'
            },
        ]
        setPaymentStatusValues(paymentStatusValues);

        let paidOptionValues = [
            {
                'id': 1,
                'type_en': 'Cash',
                'type_ar': 'نقدي',
                'type_value': 'cash'
            },
            {
                'id': 2,
                'type_en': 'Credit Card',
                'type_ar': 'بطاقة الائتمان',
                'type_value': 'credit_card'
            },
            {
                'id': 3,
                'type_en': 'Debit Card (Benefit)',
                'type_ar': 'بنفت',
                'type_value': 'debit_card_(benefit)'
            },
            {
                'id': 4,
                'type_en': 'Apple Pay',
                'type_ar': 'أبل الدفع',
                'type_value': 'apple_pay'
            },
            {
                'id': 5,
                'type_en': 'Google Pay',
                'type_ar': 'جوجل الدفع',
                'type_value': 'google_pay'
            },
            {
                'id': 6,
                'type_en': 'Bank Transfer',
                'type_ar': 'التحويل المصرفي',
                'type_value': 'bank_transfer'
            }
        ]
        setPaidOptionValues(paidOptionValues);

        initializeData(paymentStatusValues, paidOptionValues);
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    }

    const initializeData = (payment_status_values, paid_option_values) => {
        axios.get(API_URL + `/properties`)
        .then(resp => {
            let bldng = resp.data.properties;
            bldng = formatSelectData(bldng, 'properties');

            axios.get(API_URL + `/car/types`)
            .then(response => {
                let ct = response.data.car_types;
                ct = formatSelectData(ct, 'car_types');

                axios.get(API_URL + `/packages`)
                .then(response2 => {
                    let pckge = response2.data.packages;
                    setPackages(pckge)
                    setAllPackages(pckge)
                    pckge = formatSelectData(pckge, 'packages');

                    axios.get(API_URL + `/days`)
                    .then(resp1 => {
                        let dys = resp1.data.days;
                        dys = formatSelectData(dys, 'days');

                        axios.get(API_URL + `/shifts`)
                        .then(resp2 => {
                            let shf = resp2.data.shifts;
                            localStorage.setItem('shifts', JSON.stringify(shf));
                            setShiftsList(shf);
                            setAllShifts(resp2.data.shifts);
                            shf = formatSelectData(shf, 'shifts');

                            if(params.id > 0) {
                                axios.get(API_URL + `/booking/${params.id}`)
                                .then(response3 => {
                                    let bkng = response3.data.booking;
                                    if(bkng) {
                                        setBooking(bkng);
                                        setPaymentReceived(bkng?.payment_received == 1 ? true : false);
                                        localStorage.setItem('booking', JSON.stringify(bkng));

                                        let payment_status = '';
                                        if(
                                            bkng.tap_charge_id ||
                                            (bkng?.paid_option == 'Cash' || bkng?.paid_option == 'Card' || bkng?.paid_option == 'Bank transfer') ||
                                            bkng?.payment_method_id > 0
                                         ) {
                                            payment_status = 'others'
                                        } else {
                                            payment_status = 'not_paid';
                                        }
                                        setPaidOption({})
                                        setSelectedPaidOption('')
                                        if(payment_status_values) {
                                            payment_status_values.forEach(p => {
                                                if(p.type_value == payment_status) {
                                                    setPaymentStatus(p)
                                                    let translation_key = 'addbooking.' + payment_status.replace('_', '')
                                                    setSelectedPaymentStatus(t(translation_key))
                                                }
                                            })
                                        }
                                        
                                        let payment_method_match = false;
                                        if(paid_option_values) {
                                            let paid_option = bkng.paid_option?.toLowerCase()?.replace(' ', '_')
                                            paid_option_values.forEach(p => {
                                                if(p.type_value == paid_option) {
                                                    payment_method_match = true;
                                                    setPaidOption(p)
                                                    let translation_key = 'addbooking.' + paid_option?.replace('_', '')
                                                    setSelectedPaidOption(p.type_en)
                                                }
                                            })
                                        }

                                        if(!payment_method_match && bkng.payment_method_id > 0) {
                                            if(paid_option_values) {
                                                paid_option_values.forEach(p => {
                                                    if(p.id == bkng.payment_method_id) {
                                                        setPaidOption(p)
                                                        setSelectedPaidOption(p.type_en)
                                                    }
                                                })
                                            }
                                        }

                                        if(bkng.promo_code) {
                                            setPromoCodeApplied(true);
                                        }
                                        setPromoCode(bkng.promo_code);
                                        setAmount(bkng.amount);

                                        setFlatNumber(bkng.flat_number);
                                        setSelectedBuilding(bkng.property_id);
                                        if(bkng.property_id) { 
                                            setSelectedBuildingObject(valueFromId(bldng, bkng.property_id)); 
                                            getParkingLevels(bkng.property_id,  bkng.parking_level_id);
                                        }
                                        if(bkng.booking_items?.length > 0) {
                                            let bkngitem = bkng.booking_items?.filter(bi => {
                                                return bi.id == params.itemid
                                            })
                                            if(bkngitem?.length > 0) {
                                                setBookingItem(bkngitem[0])
                                                setQuantity(bkngitem[0].quantity);
                                                setSelectedCarTypeObject(valueFromId(ct, bkngitem[0].car_type_id)); 
                                                setPlateNumber(bkngitem[0].plate_number)
                                                setSelectedPackageObject(valueFromId(pckge, bkngitem[0].package_id)); 

                                                if(pckge?.length > 0) {
                                                    let pckge_item = pckge?.filter(pc => {
                                                        return pc.value == bkngitem[0].package_id
                                                    })
                                                    if(pckge_item?.length > 0) {
                                                        setPackageName(pckge_item[0].label)
                                                    }
                                                }
                                            }
                                        }

                                        setStartingDate(bkng.starting_date)
                                        if(bkng.booking_schedules?.length > 0) {
                                            let bkngsched = bkng.booking_schedules?.filter(bi => {
                                                return bi.booking_item_id == params.itemid
                                            })
                                            if(bkngsched?.length > 0) {
                                                setSelectedShiftObject(valueFromId(shf, bkngsched[0].booking_shift_id)); 
                                            }
                                        }
                                        if(bkng.booking_days?.length > 0) {
                                            let bkds = [];
                                            bkng.booking_days.forEach(bkd => {
                                                let day = ''
                                                if(bkd?.day == 'Mon') {
                                                    day = 'Monday'
                                                }
                                                if(bkd?.day == 'Tue') {
                                                    day = 'Tuesday'
                                                }
                                                if(bkd?.day == 'Wed') {
                                                    day = 'Wednesday'
                                                }
                                                if(bkd?.day == 'Thu') {
                                                    day = 'Thursday'
                                                }
                                                if(bkd?.day == 'Fri') {
                                                    day = 'Friday'
                                                }
                                                if(bkd?.day == 'Sat') {
                                                    day = 'Saturday'
                                                }
                                                if(bkd?.day == 'Sun') {
                                                    day = 'Sunday'
                                                }

                                                bkds.push({
                                                    booking_id: bkd.booking_id,
                                                    created_at: bkd.created_at,
                                                    day,
                                                    id: bkd.id,
                                                    updated_at: bkd.updated_at
                                                })
                                                
                                            })
                                            let booking_dys = formatSelectData(bkds, 'booking_days');
                                            setSelectedDayObject(booking_dys);
                                        }

                                        axios.get(API_URL + `/building-staffs/${bkng.property_id}`)
                                        .then(response => {
                                            let staffs = response.data.staffs;
                                            setStaffSchedules(response.data?.schedules)
                                            staffs = formatSelectData(staffs, 'staffs');
                                            setSelectedStaff(bkng.assigned_staff_id);
                                            if(bkng.assigned_staff_id) { setSelectedStaffObject(valueFromId(staffs, bkng.assigned_staff_id)); }

                                            refreshStaffSchedules(bkng, shf, response.data?.schedules, bkng.assigned_staff_id, 'initial');
                                        })

                                        axios.get(API_URL + `/v2-customers`)
                                        .then(response => {
                                            let users = response.data.customers;
                                            setAllCustomers(users);
                                            let custs = formatSelectData(users, 'customers');
                                            if(bkng.user_id > 0) {
                                                setSelectedCustomerObject(valueFromId(custs, bkng.user_id));
                                                let cust_info = {
                                                    id: bkng?.customer?.id,
                                                    guestname: bkng?.customer?.first_name + ' ' + bkng?.customer?.last_name,
                                                    email: bkng?.customer?.email,
                                                    phonenumber: bkng?.customer?.phone_number,
                                                }
                                                setCustomerInfo(cust_info);
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    })
                })
            })
        })  
    }

    const getParkingLevels = (property_id, parking_level_id) => {
        // Get parking levels
        axios.get(API_URL + `/v2/property/parking-levels/${property_id}`)
        .then(response => {
            let pls = response.data.parking_levels;
            let format_pls = formatSelectData(pls, 'parking_levels');
            if(parking_level_id > 0) {
                setSelectedParkingLevelObject(valueFromId(format_pls, parking_level_id)); 
            }
        })
    }

    const handleParkingLevelChange = async(data) => {
        setSelectedParkingLevelObject(data);
        setSelectedParkingLevel(data);
    }

    const refreshStaffSchedules = (bookings, shifts, schedules, staff, mode) => {
        let shf = shifts || shiftsList
        let sch = schedules || staffSchedules
        let stf = staff || selectedStaff
        
        let list = []
        let booking_schedules = bookings?.booking_schedules?.filter(bk => {
            return bk.booking_id = params.id && bk.booking_item_id == params.itemid
        })
        if(booking_schedules?.length > 0) {
            booking_schedules.forEach(bs => {
                let shift = shf?.filter(sh => {
                    return sh.value == bs?.booking_shift_id || sh.id == bs?.booking_shift_id
                })

                let sched = schedules?.filter(sc => {
                    return sc?.booking_date == bs?.booking_date && sc?.assigned_staff_id == stf
                })

                list.push({
                    booking_schedule_id: bs.id,
                    booking_id: bs.booking_id,
                    booking_item_id: bs.booking_item_id,
                    booking_date: bs.booking_date,
                    time: shift?.length > 0 ? (shift[0]?.label || shift[0].name) : '',
                    assigned: sched?.length > 0 ? sched[0].assigned : '',
                    complete: bs.status_id == 3 ? true : false,
                    not_found: bs.not_found,
                    resumed_date: bs.resumed_date,
                    rescheduled_date: bs.rescheduled_date,
                    status_id: bs.status_id,
                })
            })
        }
        
        if(list?.length > 0) {
            list = list.sort((a, b) => {
                const oldDate = new Date(a.booking_date)
                const newDate = new Date(b.booking_date)
          
                return oldDate - newDate
            })
        }
        setList(list)
        setTableSchedules(list)
        console.log('list 123', list)

        if(mode == 'initial') {
            let table_columns = [
                {
                    name: t('history.date'),
                    selector: row => row.booking_date, //getBookingDate(row),
                    sortable: true,
                    cell: row => <CustomDate row={row} />,
                },
                {
                    name: t('feedback.time'),
                    selector: row => row.time, //getBookingTime(row, sh),
                    sortable: true,
                },
                {
                    name: t('booking.assigned'),
                    selector: row => row.assigned, //getAssigned(row, sc),
                    sortable: true,
                },
                {
                    name: t('booking.status'),
                    selector: row => row.status_id == 3 ? 1 : 0, //getAssigned(row, sc),
                    sortable: true,
                    cell: row => <CustomStatus row={row} />,
                }
            ];
            setColumns(table_columns);
        }
    }

    const CustomDate = ({ row }) => (
        <div>
            {}
            <div>
                {row?.resumed_date ? 
                    (<>
                        {row.booking_date}
                        <div style={{fontSize:12,marginBottom:10,color:'#9f0000'}}>{t('booking.pausedandresumeon')} {moment(row.resumed_date).format('DD/MM/YYYY')}</div>
                    </>): 
                    (
                        <>
                            {row?.status_id == 4 && row?.rescheduled_date ?
                                (<>
                                    {row.booking_date}
                                    <div style={{fontSize:12,marginBottom:10,color:'#9f0000'}}>{t('booking.skippedto')} {moment(row.rescheduled_date).format('YYYY-MM-DD')}</div>
                                </>) : 
                                (<>{row.booking_date}</>)
                            }
                        </>
                    )
                }
            </div>
        </div>
    );

    const CustomStatus = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {}
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Checkbox
                            checked={row.complete}
                            onChange={(event) => handleChangeStatus(event, row)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        {row?.complete &&
                            <ClearIcon
                                className={classes.clear} onClick={(event) => handleClearStatus(event, row)}
                            />
                        }
                        {row?.not_found == 1 &&
                            <WarningIcon
                                className={classes.notFound}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    const handleChangeStatus = (event, row) => {
        let checked = event?.target?.checked;
        let params = {
            status: checked ? 1 : 0
        }
        axios.post(API_URL + `/schedule/complete/${row.booking_schedule_id}`, params)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                refreshSchedulesStatus()
            }
        })
    };

    const handleClearStatus = (event, row) => {
        let params = {
            status: 0
        }
        axios.post(API_URL + `/schedule/uncomplete/${row.booking_schedule_id}`, params)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                refreshSchedulesStatus()
            }
        })
    };

    function refreshSchedulesStatus() {
        let shifts_list = localStorage.getItem('shifts') ? JSON.parse(localStorage.getItem('shifts')) : ''

        axios.get(API_URL + `/booking/${params.id}`)
        .then(response3 => {
            let bkng = response3.data.booking;
            if(bkng) {
                setBooking(bkng);
                let booking_item = bkng;
                localStorage.setItem('booking', JSON.stringify(bkng));
                
                axios.get(API_URL + `/building-staffs/${booking_item?.property_id}`)
                .then(response => {
                    let staffs = response.data.staffs;
                    setStaffSchedules(response.data?.schedules)
                    staffs = formatSelectData(staffs, 'staffs');
                    setSelectedStaff(booking_item?.assigned_staff_id);
                    if(booking_item?.assigned_staff_id) { setSelectedStaffObject(valueFromId(staffs, booking_item.assigned_staff_id)); }

                    refreshStaffSchedules(booking_item, (shifts_list), response.data?.schedules, booking_item.assigned_staff_id, 'initial');
                })
            }
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(mode == 'days' || mode == 'shifts' || mode == 'booking_days') {
            if(array.length > 0) {
                array.forEach(item => {
                    new_array.push({
                        value: item.id,
                        label: mode == 'shifts' ? (language == 'ar' ? `${(item.name_ar || item.day_ar)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}` : `${(item.name || item.day)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}`) 
                            : (language == 'ar' ? ((item.name_ar) ? (item.name_ar || item.day_ar) : (item.name || item.day)) : (item.name || item.day))
                    })
                })
            }
        } else {
            if(array.length > 0) {
                array.forEach(item => {
                    new_array.push({
                        value: item.id,
                        label: language == 'ar' ? ((item.name_ar || item.type || (item.first_name + ' ' + item.last_name)) ? (item.name_ar || item.type || (item.first_name + ' ' + item.last_name)) : (item.name || item.type || (item.first_name + ' ' + item.last_name))) : (item.name || item.type || (item.first_name + ' ' + item.last_name))
                    })
                })
            }
        }

        if(mode == 'package_types') { setPackageTypes(new_array); }
        if(mode == 'packages') { setPackages(new_array); }
        if(mode == 'properties') { setBuildings(new_array); }
        if(mode == 'car_types') { setCarTypes(new_array); }
        if(mode == 'staffs') { setStaffs(new_array); }
        if(mode == 'days') { setDays(new_array); }
        if(mode == 'shifts') { setShifts(new_array); }
        if(mode == 'parking_levels') { setParkingLevels(new_array); }
        if(mode == 'customers') { setCustomers(new_array); }
        return new_array;
    }

    const handleCarTypeChange = (data) => {
        setSelectedCarTypeObject(data);
        setSelectedCarType(data.value);
    };

    const handlePackageChange = (data) => {
        setSelectedPackageObject(data);
        setSelectedPackage(data);
    }

    const handleBuildingChange = (data) => {
        setSelectedBuildingObject(data);
        setSelectedBuilding(data);
    };

    function handleClick(e) {
        setFormSubmitted(true)
        if(!flatNumber && !plateNumber && !selectedCarType && !selectedPackage && !selectedBuilding) {
            showError(t('promo-codes.fillup'))
        } else {
            editBookingItem();
        }
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function editBookingItem() {
        let payload = {
            booking_id: params.id,
            booking_item_id: booking?.booking_items?.length > 0 ? booking?.booking_items[0].id : 0,
            flat_number: flatNumber,
            plate_number: plateNumber,
            car_type_id: selectedCarType ? selectedCarType : (booking?.booking_items?.length > 0 ? booking?.booking_items[0].car_type_id : 0),
            package_id: selectedPackage ? selectedPackage.value : (booking?.booking_items?.length > 0 ? booking?.booking_items[0].package_id : 0),
            property_id: selectedBuilding,
            quantity: quantity || 0,
            parking_level_id: selectedParkingLevel?.value || null,
        }

        axios.post(API_URL + `/booking-item/edit`, payload)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/bookings");
                }, 2000);
            }
        })
    }

    function handleClose(e) {
        history.push("/bookings")
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'plateNumber': 
                setPlateNumber(event.target.value);
                break;
            case 'flatNumber': 
                setFlatNumber(event.target.value);
                break;
            case 'quantity': 
                setQuantity(event.target.value);
                break;
            case 'startingDate': 
                setStartingDate(event.target.value);
                break;
            case 'amount': 
                setAmount(event.target.value);
                break;
            case 'promoCode': 
                setPromoCode(event.target.value);
                break;
        }
    };

    const handleChange = async(event, newValue) => {
        setValue(newValue);
    };

    const handleStaffChange = (data) => {
        setSelectedStaffObject(data);
        setSelectedStaff(data.value);

        refreshStaffSchedules(booking, shiftsList, staffSchedules, data.value, '')
    };

    function handleStaffClick(e) {
        setFormSubmitted(true)
        if(!selectedStaff) {
            showError(t('promo-codes.fillup'))
        } else {
            editAssignedStaff();
        }
    }

    function editAssignedStaff() {
        axios.post(API_URL + `/assigned-staff/edit/${params.id}`, {
            assigned_staff_id: selectedStaff,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/bookings");
                }, 2000);
            }
        })
    }

    const handleShiftChange = (data) => {
        setSelectedShiftObject(data);
        setSelectedShift(data.value);
    };

    const handleDayChange = (data) => {
        setSelectedDay(data);
        setSelectedDayObject(data);
    };

    function handleScheduleClick(e) {
        setFormSubmitted(true)
        if(!startingDate && !selectedDay && !selectedShift) {
            showError(t('promo-codes.fillup'))
        } else {
            editBookingSchedule();
        }
    }

    const handlePaymentStatusChange = (event) => {
        setPaidOption({})
        setSelectedPaidOption('')
        if(paymentStatusValues) {
            paymentStatusValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaymentStatus(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaymentStatus(t(translation_key))
                }
            })
        }
    }

    const handlePaidOptionChange = (event) => {
        if(paidOptionValues) {
            paidOptionValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaidOption(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaidOption(p.type_en)
                }
            })
        }
    }

    function handlePaymentStatusClick(e) {
        setFormSubmitted(true)
        editBookingPaymentStatus();
    }

    function editBookingPaymentStatus() {
        let payment_method_id = null;
        let payment_received = paymentReceived ? 1 : 0;
        let payment_mode = paidOption?.type_value?.replace('_', '');

        if(paymentStatus.type_value == 'not_paid') {
        } else {
            if(selectedPaidOption == 'Cash') {
                payment_method_id = 1;
            } else if(selectedPaidOption == 'Credit Card') {
                payment_method_id = 2;
            } else if(selectedPaidOption == 'Debit Card (Benefit)') {
                payment_method_id = 3;
            } else if(selectedPaidOption == 'Apple Pay') {
                payment_method_id = 4;
            } else if(selectedPaidOption == 'Google Pay') {
                payment_method_id = 5;
            } else if(selectedPaidOption == 'Bank Transfer') {
                payment_method_id = 6;
            }

            if(payment_mode == 'cash') {
                payment_mode = 'Cash';
            } else if(payment_mode == 'credit_card') {
                payment_mode = 'Credit Card';
            } else if(payment_mode == 'debit_card_(benefit)') {
                payment_mode = 'Debit Card (Benefit)';
            } else if(payment_mode == 'apple_pay') {
                payment_mode = 'Apple Pay';
            } else if(payment_mode == 'Google Pay') {
                payment_mode = 'Google Pay';
            } else if(payment_mode == 'google_pay') {
                payment_mode = 'Bank Transfer';
            }
        }
        
        let payload
        if(paymentStatus.type_value == 'not_paid') {
            payload = {
                booking_id: params.id,
                paid_option: paymentStatus.type_value == 'others' ? '' : paidOption.type_en,
                payment_method_id: paymentStatus.type_value == 'not_paid' ? null : paidOption.id,
                payment_received,
            }
        } else {
            payload = {
                booking_id: params.id,
                paid_option: selectedPaidOption,
                payment_method_id,
                payment_received,
            }
        }

        axios.post(API_URL + `/booking-payment-status/edit`, payload)
        .then(res => {
            if(res.status == 200) {
                if(
                    (payment_method_id > 0 && payment_method_id != 1 && payment_method_id != 6) ||
                    (payment_received == 1 && (payment_method_id == 1 || payment_method_id == 6))
                ) {
                    setProcessing(true);
                    let payment_option_value = language == 'ar' ? paidOption?.type_ar : paidOption?.type_en
                    let payload = {
                        booking_id: booking.id,
                        property_id: booking.property_id,
                        property_name: booking.property_name,
                        invoice_id: booking.zoho_invoice_id,
                        invoice_number: booking.invoice_no,
                        payment_mode,
                        payment_mode_value: payment_option_value,
                        zoho_customer_id: booking.zoho_customer_id,
                        amount: booking.amount,
                        customer_first_name: booking?.customer?.first_name,
                        customer_last_name: booking?.customer?.last_name,
                        customer_email: booking?.customer?.email,
                        customer_phone_number: booking?.customer?.phone_number,
                        staff_first_name: booking?.assigned_staff?.first_name,
                        staff_last_name: booking?.assigned_staff?.last_name,
                        payment_received: (payment_received == 1 && (payment_method_id == 1 || payment_method_id == 6)) ? payment_received : null,
                        payment_method_id,
                    }
                    console.log(payload)
                    axios.post(API_URL + `/invoice/zoho/mark-paid`, payload)
                    .then(res => {
                        setProcessing(false);
                        toast.success(t("users.successsave"), {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(function () {
                            history.push("/bookings");
                        }, 2000);
                    })
                } else {
                    toast.success(t("users.successsave"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/bookings");
                    }, 2000);
                }
            }
        })
    }

    function editBookingSchedule() {
        let bkds = [];
        if(booking.booking_days?.length > 0) {
            booking.booking_days.forEach(bkd => {
                let day = ''
                if(bkd?.day == 'Mon') {
                    day = 'Monday'
                }
                if(bkd?.day == 'Tue') {
                    day = 'Tuesday'
                }
                if(bkd?.day == 'Wed') {
                    day = 'Wednesday'
                }
                if(bkd?.day == 'Thu') {
                    day = 'Thursday'
                }
                if(bkd?.day == 'Fri') {
                    day = 'Friday'
                }
                if(bkd?.day == 'Sat') {
                    day = 'Saturday'
                }
                if(bkd?.day == 'Sun') {
                    day = 'Sunday'
                }

                bkds.push({
                    booking_id: bkd.booking_id,
                    created_at: bkd.created_at,
                    day,
                    id: bkd.id,
                    updated_at: bkd.updated_at
                })
                
            })
        }

        let sorted_days = selectedDay ? selectedDay.sort((a, b) => (a.value > b.value) ? 1 : -1) : bkds
        let payload = {
            booking_id: params.id,
            booking_item_id: booking?.booking_items?.length > 0 ? booking?.booking_items[0].id : 0,
            starting_date: startingDate,
            booking_shift_id: selectedShift ? selectedShift : (booking?.booking_schedules?.length > 0 ? booking?.booking_schedules[0].booking_shift_id : 0),
            booking_days: sorted_days,
        }

        axios.post(API_URL + `/booking-schedule/edit`, payload)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/bookings");
                }, 2000);
            }
        })
    }

    const editUser = () => {
        window.open(
            `/user/${booking?.customer?.id || customerInfo?.id}`,
            '_blank'
        );
    }

    const handlePromoCodeClick = async () => {
        if(!promoCodeApplied) {
            const response = await axios.get(API_URL + `/v2/promo-code/${promoCode}`);
            let promo_code = response && response.data ? response.data.promo_code : '';
            if(promo_code) {
                if(promo_code.percentage > 0) {
                    let updated_amount = parseFloat(amount) - parseFloat(parseFloat(amount) * (parseFloat(promo_code.percentage) / 100))
                    setPromoCodeApplied(true);
                    setAmount(updated_amount);
                } else if(promo_code.amount > 0) {
                    let updated_amount = parseFloat(amount) - parseFloat(promo_code.amount)
                    setAmount(updated_amount);
                    setPromoCodeApplied(true);
                }
            }
        } else {
            setPromoCode('');
        }
    }

    const handleCustomerChange = async(data) => {
        console.log(data);
        setSelectedCustomerObject(data);
        setSelectedCustomer(data);

        let cust_row = allCustomers?.filter(c => {
            return c.id == data?.value
        })
        let cust = {}
        if(cust_row?.length > 0) {
            cust = cust_row[0]
        }
        setCustomerInfo(cust);
        if(cust) {
            let cust_info = {
                id: cust?.id,
                guestname: cust?.name,
                email: cust?.email || '',
                phonenumber: cust?.phone_number,
            }
            setCustomerInfo(cust_info);
        }
    }

    function handleCustomerSaveClick(e) {
        setProcessing(true);
        let payload = {
            booking_id: params.id,
            user_id: selectedCustomer?.value,
        }

        axios.post(API_URL + `/booking-customer/edit`, payload)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/bookings");
                }, 2000);
            }
        })
    }

    const handleChangePaymentReceived = (event) => {
        let checked = event?.target?.checked;
        console.log('checked: ' + checked)
        setPaymentReceived(checked ? true : false);
    };

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer} id="editbooking">
                                <div className={classes.header} id="editbookingheader">
                                    <div className={classes.title}>{t('dashboard.editbooking')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <AppBar position="static" color="default">
                                    <Tabs 
                                        value={value} 
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                    >
                                        <Tab label={t('notifications.details')} icon={ 
                                            <DirectionsCarIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(0)} 
                                        />
                                        <Tab label={t('dashboard.schedule') + ' / ' + t('booking.assignedstaff')} icon={ 
                                            <ContactsIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(1)} 
                                        />
                                        <Tab label={t('history.date')} icon={ 
                                            <CalendarTodayIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(2)} 
                                        />
                                        <Tab label={t('addbooking.paymentstatus')} icon={ 
                                            <PaymentIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(3)} 
                                        />
                                        <Tab label={t('statement.customer')} icon={ 
                                            <PersonIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(4)} 
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <div>
                                        <div>
                                            <form>
                                                <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center',marginBottom:0}}>
                                                    <div className={classes.halfDiv}>
                                                        <Select
                                                            placeholder={t('users.selectbuilding')}
                                                            value={selectedBuildingObject}
                                                            onChange={handleBuildingChange}
                                                            options={buildings}
                                                        />
                                                    </div>
                                                    <div className={classes.halfDiv}>
                                                        <Select
                                                            placeholder={t('buildings.selectparkinglevel')}
                                                            value={selectedParkingLevelObject}
                                                            onChange={handleParkingLevelChange}
                                                            options={parkingLevels}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{marginTop:15,marginBottom:10}}>
                                                    <Select
                                                        placeholder={t('services.selectservice')}
                                                        value={selectedPackageObject}
                                                        onChange={handlePackageChange}
                                                        options={packages}
                                                        required
                                                    />
                                                </div>
                                                <div style={{marginTop:15,marginBottom:10}}>
                                                    <Select
                                                        placeholder={t('booking.cartype')}
                                                        value={selectedCarTypeObject}
                                                        onChange={handleCarTypeChange}
                                                        options={carTypes}
                                                        required
                                                    />
                                                </div>
                                                <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center',marginBottom:0}}>
                                                    <div className={classes.inputContainerDesc}>
                                                        <TextField 
                                                            id="plateno"
                                                            label={t('packages.quantity')}
                                                            className={classes.input}
                                                            placeholder={t('packages.quantity')}
                                                            required
                                                            error={formSubmitted && !quantity ? true : false}
                                                            value={quantity || ''}
                                                            onChange={(event) => handleFieldChange(event, 'quantity')}
                                                            type="number"
                                                        />
                                                    </div>
                                                    <div className={classes.inputContainerDesc}>
                                                        <TextField 
                                                            id="amount"
                                                            label={t('addbooking.amount')}
                                                            className={classes.input}
                                                            placeholder={t('addbooking.amount')}
                                                            required
                                                            error={formSubmitted && !amount ? true : false}
                                                            value={amount || ''}
                                                            onChange={(event) => handleFieldChange(event, 'amount')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center',marginBottom:0}}>
                                                    <div className={classes.inputContainerDesc}>
                                                        <TextField 
                                                            id="plateno"
                                                            label={t('dashboard.flatno')}
                                                            className={classes.input}
                                                            placeholder={t('dashboard.flatno')}
                                                            required
                                                            error={formSubmitted && !flatNumber ? true : false}
                                                            value={flatNumber || ''}
                                                            onChange={(event) => handleFieldChange(event, 'flatNumber')}
                                                        />
                                                    </div>
                                                    <div className={classes.inputContainerDesc}>
                                                        <TextField 
                                                            id="plateno"
                                                            label={t('addbooking.plateno')}
                                                            className={classes.input}
                                                            placeholder={t('addbooking.plateno')}
                                                            required
                                                            error={formSubmitted && !plateNumber ? true : false}
                                                            value={plateNumber || ''}
                                                            onChange={(event) => handleFieldChange(event, 'plateNumber')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center',marginBottom:0}}>
                                                    <div className={classes.halfDiv}>
                                                        <div style={{width:'90%'}}>
                                                            <TextField 
                                                                id="promocode"
                                                                label={t('promo-codes.promocode')}
                                                                className={classes.input}
                                                                placeholder={t('promo-codes.promocode')}
                                                                required
                                                                error={formSubmitted && !promoCode ? true : false}
                                                                value={promoCode || ''}
                                                                onChange={(event) => handleFieldChange(event, 'promoCode')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.halfDiv}>
                                                        <div className={classes.save1Container}>
                                                            <div className={classes.saveText}>{promoCodeApplied ? t('services.cancel') : t('services.apply')}</div>
                                                            <div 
                                                                onClick={handlePromoCodeClick}
                                                                className={classes.saveBtnContainer}
                                                                style={{marginLeft:10}}
                                                            >
                                                                <img 
                                                                    className={classes.saveBtn} 
                                                                    src={arrowrightwhiteimage} 
                                                                    alt="Carcare" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div>
                                            <div className={classes.saveContainer}>
                                                <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                <div 
                                                    onClick={handleClick}
                                                    className={classes.saveBtnContainer} 
                                                >
                                                    <img 
                                                        className={classes.saveBtn} 
                                                        src={arrowrightwhiteimage} 
                                                        alt="Carcare" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div>
                                        <div>
                                            <div style={{marginTop:20}}>
                                                <b>{t('statement.scheduleddate')}</b>
                                                <div>
                                                    <DataTable
                                                        columns={columns}
                                                        data={tableSchedules}
                                                        pagination
                                                        paginationResetDefaultPage={resetPaginationToggle}
                                                        persistTableHead
                                                        fixedHeader
                                                    />
                                                </div>
                                            </div>
                                            <form>
                                                <div>
                                                    <Select
                                                        placeholder={t('booking.assignedstaff')}
                                                        value={selectedStaffObject}
                                                        onChange={handleStaffChange}
                                                        options={staffs}
                                                        name="staff"
                                                        required
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div>
                                            <div className={classes.saveContainer} style={{marginTop:20}}>
                                                <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                <div 
                                                    onClick={handleStaffClick}
                                                    className={classes.saveBtnContainer} 
                                                >
                                                    <img 
                                                        className={classes.saveBtn} 
                                                        src={arrowrightwhiteimage} 
                                                        alt="Carcare" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <div>
                                        <div>
                                            <b>{t('packages.package')}</b>: {packageName}
                                            <form>
                                                {booking?.booking_schedules?.length > 0 &&
                                                    <>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="startingDate"
                                                                type="date"
                                                                className={classes.input}
                                                                placeholder={t('packages.startingdate')}
                                                                value={startingDate || ''}
                                                                onChange={(event) => handleFieldChange(event, 'startingDate')}
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div style={{marginTop:25}}>
                                                            <Select
                                                                placeholder={t('buildings.selectdays')}
                                                                value={selectedDayObject}
                                                                onChange={handleDayChange}
                                                                options={days}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc} style={{marginTop:25}}>
                                                            <Select
                                                                placeholder={t('users.selectshift')}
                                                                value={selectedShiftObject}
                                                                onChange={handleShiftChange}
                                                                options={shifts}
                                                                required
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </form>
                                        </div>
                                        <div>
                                            <div className={classes.saveContainer}>
                                                <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                <div 
                                                    onClick={handleScheduleClick}
                                                    className={classes.saveBtnContainer} 
                                                >
                                                    <img 
                                                        className={classes.saveBtn} 
                                                        src={arrowrightwhiteimage} 
                                                        alt="Carcare" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <div>
                                        <div>
                                            <form>
                                                {paymentStatusValues && paymentStatusValues.map((ps) => (
                                                    <div>
                                                        <FormControlLabel
                                                            value="end" 
                                                            control={<Radio 
                                                                checked={paymentStatus.type_value == ps.type_value}
                                                                onChange={handlePaymentStatusChange}
                                                                value={ps.type_value}
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': ps.type_en }} />} 
                                                            label={localStorage.getItem('language') == 'ar' ? (ps.type_ar ? ps.type_ar : ps.type_en) : ps.type_en} />
                                                    </div>
                                                ))}
                                                {paymentStatus.type_value == 'others' &&
                                                    <>
                                                        <div style={{marginTop:15}}>{t('profile.paymentmethods')}: </div>
                                                        <div>
                                                            {paidOptionValues && paidOptionValues.map((pd) => (
                                                                <div>
                                                                    <FormControlLabel
                                                                        value="end" 
                                                                        control={<Radio 
                                                                            checked={paidOption.type_value == pd.type_value}
                                                                            onChange={handlePaidOptionChange}
                                                                            value={pd.type_value}
                                                                            name="radio-button-demo"
                                                                            inputProps={{ 'aria-label': pd.type_en }} />} 
                                                                        label={localStorage.getItem('language') == 'ar' ? (pd.type_ar ? pd.type_ar : pd.type_en) : pd.type_en} />
                                                                </div>
                                                            ))}
                                                            {(paidOption?.type_value == 'cash' || paidOption?.type_value == 'bank_transfer') &&
                                                                <>
                                                                    <div style={{marginTop:15}}>{t('addbooking.paymentstatus')}: </div>
                                                                    <div className={classes.inputContainerDesc}>
                                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                                            <Checkbox
                                                                                checked={paymentReceived}
                                                                                onChange={(event) => handleChangePaymentReceived(event)}
                                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            />
                                                                            <div>{t('addbooking.paymentreceived')}</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                    <div>
                                        {!processing &&
                                            <>
                                                <div className={classes.saveContainer}>
                                                    <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                    <div 
                                                        onClick={handlePaymentStatusClick}
                                                        className={classes.saveBtnContainer} 
                                                    >
                                                        <img 
                                                            className={classes.saveBtn} 
                                                            src={arrowrightwhiteimage} 
                                                            alt="Carcare" />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {processing &&
                                            <div>Processing, please wait...</div>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                <div>
                                    <div><b>{t('statement.customer')}</b></div>
                                    <div style={{marginTop:20}}>
                                        <div style={{marginBottom:15}}>
                                            <Select
                                                placeholder={t('customers.selectcustomer')}
                                                value={selectedCustomerObject}
                                                onChange={handleCustomerChange}
                                                options={customers}
                                            />
                                        </div>
                                        <div style={{marginBottom:5}}>
                                            <b>ID: </b>
                                            <a href="#" onClick={() => editUser()} target="_blank">{customerInfo?.id}</a>
                                        </div>
                                        <div style={{marginBottom:5}}>
                                            <b>{t('buildings.name')}: </b>
                                            <a href="#" onClick={() => editUser()} target="_blank">{customerInfo?.guestname}</a>
                                        </div>
                                        <div style={{marginBottom:5}}>
                                            <b>{t('addbooking.email')}: </b>
                                            {customerInfo?.email}
                                        </div>
                                        <div style={{marginBottom:5}}>
                                            <b>{t('addbooking.phone')}: </b>
                                            {customerInfo?.phonenumber}
                                        </div>
                                    </div>
                                    <div>
                                        {!processing &&
                                            <>
                                                <div className={classes.saveContainer}>
                                                    <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                    <div 
                                                        onClick={handleCustomerSaveClick}
                                                        className={classes.saveBtnContainer} 
                                                    >
                                                        <img 
                                                            className={classes.saveBtn} 
                                                            src={arrowrightwhiteimage} 
                                                            alt="Carcare" />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {processing &&
                                            <div>Processing, please wait...</div>
                                        }
                                    </div>
                                </div>
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default EditBooking;