import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.scss';
import { ReactComponent as NotFoundSvg } from '../../src/404.svg';

const NotFound = () => {
    const id = localStorage.getItem('id');
    let homeUrl = '/login';
    if(id) {
        homeUrl = '/dashboard';
    }

    return(
        // <div>
        //     <h1>404 - Not Found!</h1>
        //     <Link to={homeUrl}>
        //         Go Home
        //     </Link>
        // </div>
        <>
            <div className="background">
                <div className="ground"></div>
            </div>
            <div className="container">
                <div className="left-section">
                    <div className="inner-content">
                        <h1 className="heading">404</h1>
                        <p className="subheading">Looks like the page you were looking for is no longer here.</p>
                        <div className="take-me-back">
                            <Link to={homeUrl}>
                                Take me back
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="right-section">
                    <NotFoundSvg />
                </div>
            </div>  
        </>
    );
};

export default NotFound;