import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { API_URL } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import Title from "../components/Title";
import OtherTopMenu from '../components/OtherTopMenu';
import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 48,
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    forgotPasswordContainer: {
        textAlign: 'left',
        marginTop: 30
    },
    forgotPassword: {
        color: '#a7a7a7',
        fontSize: 16
    },
    forgotPasswordContainer: {
        paddingTop: 60,
        float: 'right'
    },
    forgotPasswordText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -55,
        width: 44
    },
    forgotPasswordBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    forgotPasswordBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: -11,
        marginTop: 6
    },
    errorMessage: {
        color: '#fff',
        fontSize: 14,
        marginTop: 9,
        backgroundColor: '#cc0000',
        padding: 10,
        textAlign: 'center',
        fontWeight:'bold'
    }
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const emailRef = useRef('');
    const history = useHistory();

    function handleClick(e) {
        e.preventDefault();
    
        let email = emailRef.current.value;

        if(email) {
            axios.post(API_URL + `/user/forgot/password`, { 
                email: email
            })
            .then(res => {
                if(res.status == 200) {
                    toast.success("New password was sent to your email.", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/login")
                    }, 5000);
                } else {
                    toast.error("There was an error processing your request", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }  else {
            toast.error("Please enter your email", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    return (
      <div className='inner'>
            <OtherTopMenu />
            <Title lineContent='Forgot password' lineContent2="Give us your email, we'll reset your password." />
            <div className={classes.fieldsContainer}>
                <ToastContainer />
                <form>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="email"
                            label="Email" 
                            className={classes.input}
                            placeholder="Email"
                            type="email"
                            inputRef={emailRef}
                        />
                    </div>
                </form>
                <div className={classes.inputContainer}>
                    <div className={classes.forgotPasswordContainer}>
                        <div className={classes.forgotPasswordText}>Reset</div>
                        <div 
                            onClick={handleClick}
                            className={classes.forgotPasswordBtnContainer} 
                        >
                            <img 
                                className={classes.forgotPasswordBtn} 
                                src={arrowrightwhiteimage} 
                                alt="Carcare" />
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
};
  
export default ForgotPassword;